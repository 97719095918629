@charset "UTF-8";


/*------------------------------------------------------------------

1.  Body / Typography 
2.  Form Elements
3.  Buttons
4.  General / Elements
        4.1 Section space
        4.2 Margin space
        4.3 Padding space
        4.4 Background color
        4.5 Features
        4.7 Call to actions
        4.8 Img hover effect
        4.9 Icon
        -----------------------
5. Header / Navigations
        5.1 Header Regular
        5.2 Header Transparent
        5.3 Navigation Regular
        5.4 Page Header

        -----------------------
6. Slider
        6.1 Slider
        -----------------------
7. Content
8. Footers
9. Pages
    9.1  Service
    9.2  Service Single / sidebar with sidenav
    9.3  Testimonial
    9.4  Contact us
    9.5  Styleguide
    9.6  404 error
    ------------------------------------
10.Blog
    10.1 Blog Default
    10.2 Blog Single
    ------------------------------------
11.Sidebar
    11.1 Widget
    ------------------------------------

13. Media Queries

14. Bootstrap mods

------------------------------------------------------------------*/

/*-----------------------------------
    1. Body / Typography 
-----------------------------------*/

body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    letter-spacing: 0px;
    color: rgb(50, 50, 50);
    font-weight: 300;
    line-height: 32px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    color: #302e2e;
    font-family: 'Arapey', sans-serif;
    margin: 0px 0px 15px 0px;
    letter-spacing: 0px;
}

h1 {
    font-size: 30px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 5px;
    letter-spacing: 1px;
  }

h1:before {
    width: 28px;
    height: 5px;
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -14px;
}

h1:after {
    width: 100px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 25px;
    left: 50%;
    margin-left: -50px;
}

h2 {
    line-height: 30px;
    text-align: center;
    font-size:30px;
    font-weight:300;
    letter-spacing:1px;
    text-transform: uppercase;
    position: relative;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    grid-gap: 20px;
    align-items: center;
}
    
h2:after, h2:before {
    content: " ";
    display: block;
    height: 5px;
    background-color:#f8f8f8;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 16px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 12px;
}

p {
    margin: 0 0 30px;
    line-height: 1.6;
}

.about-list p {
    margin: 0 0 10px;
}

p:last-child {
    margin: 0px;
}

ul,
ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: #302e2e;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

a:focus,
a:hover {
    text-decoration: none;
    color: #84cc70;
}

.lead {
    font-style: italic;
    font-size: 21px;
    font-weight: 400;
    line-height: 1.6;
    font-family: 'Rubik', sans-serif;
    color: #a5d897;
    margin-bottom: 30px;
}

.blockquote {
    padding: 50px;
    border-radius: 2px;
    border: 0px;
    font-size: 32px;
    text-align: center;
    color: #333333;
    background-color: #f1f7ef;
}

.blockquote-content p {
    font-style: italic;
    font-family: 'Rubik', sans-serif;
    color: #a5d897;
    margin-bottom: 30px;
    line-height: 1.4;
    font-weight: 400;
}

.blocquote-author {
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    font-style: normal;
    color: #302e2e;
    letter-spacing: 1px;
}

blockquote p {
    font-family: 'Rubik', sans-serif;
    color: #302e2e;
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;
    font-style: italic;
    padding-left: 15px;
}

strong {
    color: #333333;
    font-weight: 600;
    margin-right: 8px;
}

hr {
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 40px;
    margin-top: 40px;
}


/*----------------------- 
    2 Form Elements:
-------------------------*/

label {}

.control-label {
    font-size: 14px;
    text-transform: capitalize;
    color: #606060;
    margin-bottom: 10px;
}

.form-control:focus {}

.textarea.form-control {}

.required {}

.form-group {
    margin-bottom: 5px;
}

.form-check .error_msg, .form-group .error_msg {
    color: #dc3545;
    text-align: left;
    font-size:0.7rem;
    margin-top: -5px;
    margin-bottom: 10px;
}

.form-control {
    border-radius: 2px;
    text-transform: capitalize;
    color: #7d7a79;
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    height: 48px;
    padding: 12px;
    line-height: 1.42857143;
    background-image: none;
    border: 1px solid #e6e6e6;
    background-color: #fff;
    margin-bottom: 10px;
    -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, .075);
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, .075);
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

input[type=checkbox],
input[type=radio] {
    margin: 8px 0 0;
    margin-top: 1px\9;
    line-height: normal;
}

.input-group-addon {
    border-radius: 0px;
}

#datepicker.form-control {
    border-right: transparent !important;
}

.default-skin .ui-widget-header {
    border: 1px solid #ecaa30;
    background: #ecaa30;
    color: #fff;
    font-weight: bold;
}

.default-skin .ui-state-highlight,
.default-skin .ui-widget-content .ui-state-highlight,
.default-skin .ui-widget-header .ui-state-highlight {
    border: 1px solid #ecaa30;
    background: #ecaa30;
    color: #fff;
}


/*-------------- align ------------*/


/* =WordPress Core
-------------------------------------------------------------- */

.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float: right;
    margin: 0px 0 30px 30px;
}

.alignleft {
    float: left;
    margin: 0px 30px 0px 0;
}

a img.alignright {
    float: right;
    margin: 0px 0 30px 30px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%;
    /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
}


/*-----------------------------------
    3. Buttons 
-----------------------------------*/

.btn {
    font-family: 'Rubik', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 14px;
    margin-bottom: 4px;
    letter-spacing: 1px;
    border-radius: 2px;
    border: transparent;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.btn-primary {
    background-color: #f47e5e;
    color: #fff;
}

.btn-primary:hover {
    background-color: #e56f4f;
    color: #fff;
}

.btn-primary.focus,
.btn-default:focus {
    background-color: #e56f4f;
    color: #fff;
}

.btn-default {
    background-color: #84cc70;
    color: #fff;
}

.btn-default:hover {
    background-color: #72bb5d;
    color: #fff;
}

.btn-default.focus,
.btn-primary:focus {
    background-color: #72bb5d;
    color: #fff;
}

.btn-lg {
    padding: 15px 20px;
    font-size: 14px;
}

.btn-sm {
    padding: 12px 16px;
    font-size: 12px;
}

.btn-xs {
    padding: 7px 12px;
    font-size: 11px;
}

.btn-link {
    color: #f47e5e;
    border-bottom: 2px solid #f47e5e;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.btn-link:hover {
    color: #e56f4f;
    border-bottom: 2px solid #e56f4f;
    text-decoration: none;
}

button.btn {
    margin-right: -1px;
}


/*-----------------------------------
    4. General / Elements 
-----------------------------------*/


/*----------------------- 
    4.1 Section space :
-------------------------*/


/*Note: Section space help to create top bottom space;*/

.space-small {
    padding-top: 40px;
    padding-bottom: 40px;
}

.space-medium {
    padding-top: 80px;
    padding-bottom: 80px;
}

.space-large {
    padding-top: 120px;
    padding-bottom: 120px;
}

.space-ex-large {
    padding-top: 140px;
    padding-bottom: 140px;
}


/*----------------------
   4.2 Margin Space
-----------------------*/

.mb0 {
    margin-bottom: 0px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb30 {
    margin-bottom: 30px;
}

.mb35 {
    margin-bottom: 35px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb60 {
    margin-bottom: 60px;
}

.mb80 {
    margin-bottom: 80px;
}

.mb100 {
    margin-bottom: 100px;
}

.mb130 {
    margin-bottom: 130px;
}

.mt0 {
    margin-top: 0px;
}

.mt10 {
    margin-top: 10px;
}

.mt20 {
    margin-top: 20px;
}

.mt30 {
    margin-top: 30px;
}

.mt40 {
    margin-top: 40px;
}

.mt60 {
    margin-top: 60px;
}

.mt70 {
    margin-top: 70px;
}

.mt80 {
    margin-top: 80px;
}

.mt100 {
    margin-top: 100px;
}


/*----- less margin space from top --*/

.mt-20 {
    margin-top: -20px;
}

.mt-40 {
    margin-top: -40px;
}

.mt-60 {
    margin-top: -60px;
}

.mt-80 {
    margin-top: -80px;
}


/*-----------------------
   4.3 Padding space
-------------------------*/

.nopadding {
    padding: 0px;
}

.nopr {
    padding-right: 0px;
}

.nopl {
    padding-left: 0px;
}

.pinside10 {
    padding: 10px;
}

.pinside20 {
    padding: 20px;
}

.pinside30 {
    padding: 30px;
}

.pinside40 {
    padding: 40px;
}

.pinside50 {
    padding: 50px;
}

.pinside60 {
    padding: 60px;
}

.pinside80 {
    padding: 80px;
}

.pinside130 {
    padding: 130px;
}

.pdt10 {
    padding-top: 10px;
}

.pdt20 {
    padding-top: 20px;
}

.pdt30 {
    padding-top: 30px;
}

.pdt40 {
    padding-top: 40px;
}

.pdt60 {
    padding-top: 60px;
}

.pdt80 {
    padding-top: 80px;
}

.pdb10 {
    padding-bottom: 10px;
}

.pdb20 {
    padding-bottom: 20px;
}

.pdb30 {
    padding-bottom: 30px;
}

.pdb40 {
    padding-bottom: 40px;
}

.pdb60 {
    padding-bottom: 60px;
}

.pdb80 {
    padding-bottom: 80px;
}

.pdb150 {
    padding-bottom: 150px;
}

.pdl10 {
    padding-left: 10px;
}

.pdl20 {
    padding-left: 20px;
}

.pdl30 {
    padding-left: 30px;
}

.pdl40 {
    padding-left: 40px;
}

.pdl60 {
    padding-left: 60px;
}

.pdl80 {
    padding-left: 80px;
}

.pdr10 {
    padding-right: 10px;
}

.pdr20 {
    padding-right: 20px;
}

.pdr30 {
    padding-right: 30px;
}

.pdr40 {
    padding-right: 40px;
}

.pdr60 {
    padding-right: 60px;
}

.pdr80 {
    padding-right: 80px;
}

.pdtb40 {
    padding-bottom: 40px;
    padding-top: 40px;
}


/*--------------------------------
4.4 Background & Block color 
----------------------------------*/

.bg-light {
    background-color: #f8faf5;
}

.bg-primary {
    background-color: #ec1010;
    color: #fff;
}

.bg-default {}

.bg-white {
    background-color: #fff;
}

.outline {}

.section-title {
    margin-bottom: 40px;
    text-align: center;
}

.title {
    color: #333333;
}

.small-title {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 900;
    color: #ec1010;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
}

.text-white {
    color: #fff;
}

.text-black {}

.primary-sidebar {}

.secondary-sidebar {}

.well-block {}

.divider-line {
    border-bottom: 1px solid #1d4293;
    margin-top: 40px;
    margin-bottom: 40px;
}


/*--------------------------------
4.5 Features Block 
----------------------------------*/

.about-bg-block {
    padding-top: 75px;
    padding-bottom: 60px;
    color: #fff;
}

.feature-block {}

.feature-content {
    margin-bottom: 40px;
    padding: 15px;
    padding-left: 50px;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.feature-image {
    border-radius: 15px;
    margin-bottom: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 10px;
    background-color: #fff;
}

.feature-link {
    font-size: 20px;
    font-weight: 500;
}

.feature-section {}

.feature-icon-circle {
    height: 0px;
}

.feature-icon i {
    font-size: 40px;
    margin: 10px;
}

.feature-icon {
    border-radius: 50px;
    width: 60px;
    height: 60px;
    position: relative;
    top: -45px;
    left: -80px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.1);
}

.feature-icon ul {}

.feature-icon ul li {}

.feature-icon ul li i {}


/*--------------------------------
4.6 Bullet/arrow
----------------------------------*/

.angle {
    position: relative;
    color: #7d7a79;
    font-weight: 400;
    line-height: 34px;
}

.angle-right li:before {
    font-family: FontAwesome;
    display: block;
    position: absolute;
    left: 0;
    font-size: 14px;
    padding-top: 2px;
    color: #f47e5e;
    content: "\f138";
}


/*--------------------------------
4.7 Call to actions 
----------------------------------*/

.cta-section {
    background-color: #f5f6f8;
    padding-top: 50px;
    padding-bottom: 50px;
}

.cta-block {}

.cta-text {}

.cta-title {}

.cta-btn {
    margin-top: 16px;
}

.widget-cta {
    padding: 30px;
    border-radius: 2px;
    background-color: #f47e5e;
}

.widget-cta-block {
    color: #fff;
}

.widget-cta-icon {
    font-size: 45px;
    color: #fff;
    margin-bottom: 30px;
}

.widget-cta-content {
    margin-bottom: 15px;
}

.widget-cta-title {
    color: #fff;
    margin: 0px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 14px;
    font-weight: 500;
}

.widget-cta-text {
    color: #fff;
}


/*--------------------------------
 4.8 Image Hover Effect 
----------------------------------*/

img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}

.imghover {
    display: block;
    position: relative;
    max-width: 100%;
}

.imghover::before,
.imghover::after {
    display: block;
    position: absolute;
    content: "";
    text-align: center;
    opacity: 0;
}

.imghover::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.imghover::after {
    top: 55%;
    left: 50%;
    padding: 10px;
    line-height: 10px;
    margin: -25px 0 0 -25px;
    content: "\f00e";
    font-family: "FontAwesome";
    font-size: 30px;
}

.imghover:hover::before,
.imghover:hover::after {
    opacity: 1;
}

.imghover:hover::before {
    background: rgba(135, 188, 120, .80);
}

.imghover:hover::after {
    color: #fff;
}


/*--------------------------------
4.9 Icon 
----------------------------------*/

.icon {}

.icon-1x {
    font-size: 18px;
}


/* default icon size of font*/

.icon-2x {
    font-size: 38px;
}

.icon-4x {
    font-size: 65px;
}

.icon-6x {
    font-size: 88px;
}

.icon-8x {
    font-size: 108px;
}

.icon-default {
    color: #aa9144;
}

.icon-primary {
    color: #474954;
}

.icon-secondary {
    color: #fff;
}

.icon-white {
    color: #fff;
}


/*--------------------------------
5 Header / Navigations 
----------------------------------*/


/*--------------------
    5.1 Header Regular 
   ----------------------*/

.logo {}

.top-bar {
    background-color: #7cad6f;
    border-bottom: #6f9d63 1px solid;
    padding-top: 3px;
    padding-bottom: 3px;
    box-shadow: 0px 0px 5px #000000;
    z-index: 100;
    position: relative;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-bottom: -11px;
}

.call-info {
    margin-top: 5px;
}

.call-text {
    font-size: 15px;
}

.call-text strong {
    margin-left: 10px;
    font-weight: 400;
}

.social {}

.social ul {
    float: right;
}

.social ul li {
    float: left;
}

.social ul li a {
    font-size: 13px;
    padding: 12px 10px;
    color: #b6e1ab;
}

.social ul li a:hover {
    color: #fff;
}

.header {}

.header-wrapper {
    z-index: 9;
    background: #fff;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #87bc78;
}

.is-sticky .header-wrapper {}


/*--------------------------
    5.2 Navigations Regular
   ----------------------------*/

.hero-section {
    /*background: url(../images/hero-img.jpg) no-repeat center;*/
    background-size: cover;
}

.hero-section-caption {
    margin-top: 110px;
    margin-bottom: 110px;
    background-color: #4d8638;
    border-radius: 6px;
}

.hero-title {
    color: #fff;
    font-size: 35px;
    font-weight: 100;
    line-height: 45px;
    margin-bottom: 25px;
}

#navigation {
    float: right;
}

#navigation,
#navigation ul,
#navigation ul li,
#navigation ul li a,
#navigation #menu-button {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    line-height: 1;
    display: block;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#navigation:after,
#navigation > ul:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

#navigation #menu-button {
    display: none;
}

#navigation {
    padding-top: 5px;
    font-family: 'Rubik', sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

#navigation > ul > li {
    float: left;
}

#navigation.align-center > ul {
    font-size: 0;
    text-align: center;
}

#navigation.align-center > ul > li {
    display: inline-block;
    float: none;
}

#navigation.align-center ul ul {
    text-align: left;
}

#navigation.align-right > ul > li {
    float: right;
}

#navigation > ul > li > a {
    padding: 15px;
    font-size: 13px;
    letter-spacing: 1px;
    text-decoration: none;
    font-weight: 500;
    text-transform: uppercase;
}

#navigation > ul > li:hover > a {
    background-color: transparent;
}

#navigation > ul > li.has-sub > a {
    padding-right: 32px;
}

#navigation > ul > li > a:after {
    position: absolute;
    top: 16px;
    right: -3px;
    height: 2px;
    display: block;
    content: ' ';
}

#navigation > ul > li.has-sub > a:before {
    position: absolute;
    right: 14px;
    font-family: FontAwesome;
    content: "\f107";
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}


/*#navigation > ul > li.has-sub:hover > a:before {
 top: 23px;
 height: 0;
}*/

#navigation ul ul {
    position: absolute;
    left: -9999px;
}

#navigation.align-right ul ul {
    text-align: right;
}

#navigation ul ul li {
    top: 23px;
    height: 0;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

#navigation li:hover > ul {
    left: auto;
}

#navigation.align-right li:hover > ul {
    left: auto;
    right: 0;
}

#navigation li:hover > ul > li {
    height: 42px;
}

#navigation ul ul ul {
    margin-left: 100%;
    top: 0;
}

#navigation.align-right ul ul ul {
    margin-left: 0;
    margin-right: 100%;
}

#navigation ul ul li a {
    background-color: #87bc78;
    padding: 14px 22px;
    width: 218px;
    font-size: 13px;
    text-decoration: none;
    color: #dbf9d3;
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: #7cad6f 1px solid;
    letter-spacing: 1px;
}

#navigation ul ul li:first-child > a {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

#navigation ul ul li:last-child > a {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

#navigation ul ul li:last-child > a,
#navigation ul ul li.last-item > a {
    border-bottom: 0;
}

#navigation ul ul li.has-sub > a:after {
    position: absolute;
    top: 16px;
    right: 11px;
    width: 8px;
    height: 2px;
    display: block;
    background: #dddddd;
    content: '';
}

#navigation.align-right ul ul li.has-sub > a:after {
    right: auto;
    left: 11px;
}

#navigation ul ul li.has-sub > a:before {
    position: absolute;
    top: 13px;
    right: 14px;
    display: block;
    width: 2px;
    height: 8px;
    background: #dddddd;
    content: '';
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

#navigation.align-right ul ul li.has-sub > a:before {
    right: auto;
    left: 14px;
}

#navigation ul ul > li.has-sub:hover > a:before {
    top: 17px;
    height: 0;
}

#navigation.small-screen {
    width: 100%;
}

#navigation.small-screen ul {
    width: 100%;
    display: none;
}

#navigation.small-screen.align-center > ul {
    text-align: left;
}

#navigation.small-screen ul li {
    width: 100%;
    border-top: 1px solid #6f9d63;
    top: auto;
    background-color: #7cad6f;
}

#navigation.small-screen ul ul li,
#navigation.small-screen li:hover > ul > li {
    height: auto;
}

#navigation.small-screen ul ul li:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

#navigation.small-screen ul li a,
#navigation.small-screen ul ul li a {
    width: 100%;
    border-bottom: 0;
    padding: 20px 15px;
    color: #dbf9d3;
}

#navigation.small-screen > ul > li > a:after {
    position: absolute;
    top: 16px;
    right: -3px;
    height: 2px;
    display: block;
    content: '';
}

#navigation.small-screen > ul > li {
    float: none;
    margin: 5px 0px;
    border-radius: 15px;
}

#navigation.small-screen ul ul li a {
    padding-left: 25px;
}

#navigation.small-screen ul ul ul li a {
    padding-left: 35px;
}

#navigation.small-screen ul ul li a {
    color: #dbf9d3;
    background: none;
}

#navigation.small-screen ul ul li:hover > a,
#navigation.small-screen ul ul li.active > a {
    color: #fff;
    background-color: transparent;
}

#navigation.small-screen ul ul,
#navigation.small-screen ul ul ul,
#navigation.small-screen.align-right ul ul {
    position: relative;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: left;
}

#navigation.small-screen > ul > li.has-sub > a:after,
#navigation.small-screen > ul > li.has-sub > a:before,
#navigation.small-screen ul ul > li.has-sub > a:after,
#navigation.small-screen ul ul > li.has-sub > a:before {
    display: none;
}

#navigation.small-screen #menu-button {
    display: block;
    padding: 18px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    background-color: #7cad6f;
    margin-top: 15px;
    border-radius: 15px;
}

#navigation.small-screen #menu-button:after {
    position: absolute;
    top: 16px;
    right: 17px;
    display: block;
    height: 12px;
    width: 20px;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    content: '';
}

#navigation.small-screen #menu-button:before {
    position: absolute;
    top: 21px;
    right: 17px;
    display: block;
    height: 2px;
    width: 20px;
    background: #fff;
    content: '';
}

#navigation.small-screen #menu-button.menu-opened:after {
    top: 20px;
    border: 0;
    height: 2px;
    width: 15px;
    background: #fff;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#navigation.small-screen #menu-button.menu-opened:before {
    top: 20px;
    background: #fff;
    width: 15px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#navigation.small-screen .submenu-button {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 0;
    display: block;
    border-left: 1px solid #6f9d63;
    height: 54px;
    width: 53px;
    cursor: pointer;
}

#navigation.small-screen .submenu-button.submenu-opened {}

#navigation.small-screen ul ul .submenu-button {
    height: 34px;
    width: 34px;
}

#navigation.small-screen .submenu-button:after {
    position: absolute;
    top: 25px;
    right: 20px;
    width: 8px;
    height: 2px;
    display: block;
    background: #fff;
    content: '';
}

#navigation.small-screen ul ul .submenu-button:after {
    top: 15px;
    right: 13px;
}

#navigation.small-screen .submenu-button.submenu-opened:after {
}

#navigation.small-screen .submenu-button:before {
    position: absolute;
    top: 22px;
    right: 23px;
    display: block;
    width: 2px;
    height: 8px;
    background: #fff;
    content: '';
}

#navigation.small-screen ul ul .submenu-button:before {
    top: 12px;
    right: 16px;
}

#navigation.small-screen .submenu-button.submenu-opened:before {
    display: none;
}

#navigation.small-screen.select-list {
    padding: 5px;
}


/*----------------------------------------------
    5.4 Page Header
----------------------------------------------*/

.page-header {
    text-align: left;
    margin: 0px;
    padding: 0px;
    border: 0px;
    margin-bottom: 50px;
    padding-top: 70px;
    padding-bottom: 50px;
    background-color: gray;
}

.page-section {
    padding-top: 85px;
    padding-bottom: 85px;
}

.page-description {}

.page-description p {}

.page-title {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 10px;
    font-family: 'Rubik', sans-serif;
    color: #302e2e
}

.page-text {
    color: #fff;
}


/*-----------------------------------
    6. Slider 
-------------------------------------*/

.slider {
    margin-bottom: -30px;
}

.slider-img {
    background: -moz-linear-gradient(top, rgba(13, 15, 13, 0.6) 0%, rgba(13, 15, 13, 0.6) 100%);
    background: -webkit-linear-gradient(top, rgba(13, 15, 13, 0.6) 0%, rgba(13, 15, 13, 0.6) 100%);
    background: linear-gradient(to bottom, rgba(13, 15, 13, 0.6) 0%, rgba(13, 15, 13, 0.6) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#cc1b2429', endColorstr='#cc1b2429', GradientType=0);
    position: relative;
}

.slider-img img {
    position: relative;
    z-index: -1;
}

.slider-captions {
    position: absolute;
    bottom: 140px;
    width: 100%;
    color: #fff;
}

.slider-title {
    color: #fff;
    font-size: 42px;
    font-weight: 700;
}

.slider-text {
    color: #fff;
}

.slider .owl-dots {
    text-align: center !important;
    position: relative;
    bottom: 50px;
}

.slider .owl-nav {
   
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}



.slider .owl-nav .owl-prev {
    position: absolute;
    top: 40%;
    left: 0px;
    color: #fff;
    font-size: 18px;
    margin: 8px;
    padding: 0px;
    background: transparent;
    display: inline-block;
    cursor: pointer;
    border-radius: 0px;
    height: 35px;
    width: 35px;
    border: 2px solid #fff;
    border-radius: 25px;
}

.slider .owl-nav .owl-next {
    position: absolute;
    top: 40%;
    right: 0px;
    color: #fff;
    font-size: 18px;
    margin: 8px;
    padding: 0px;
    background: transparent;
    display: inline-block;
    cursor: pointer;
    border-radius: 0px;
    height: 35px;
    width: 35px;
    border: 2px solid #fff;
    border-radius: 25px;
}

.slider .owl-nav .owl-prev:hover {background-color: #f47e5e;
    border: 2px solid #f47e5e; text-decoration: none; color: #fff;}

.slider .owl-nav .owl-next:hover { background-color: #f47e5e;
    border: 2px solid #f47e5e; text-decoration: none; color: #fff; }


.slider .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
}

.slider .owl-dots .owl-dot span {
    width: 11px;
    height: 11px;
    margin: 5px 4px;
    background: transparent;
    border-radius: 5px;
    border: 2px solid #fff;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
}

.slider .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background-color: #f47e5e;
    border: 2px solid #f47e5e;
}

.slider .owl-carousel .owl-item img {
    width: 100%;
}


/*-----------------------------------
7. before after gallery
-------------------------------------*/

.ba-gallery {
    margin-bottom: 30px;
    position: relative;
}

.ba-gallery img {
    width:100%;
}

.left-btn,
.right-btn {
    font-family: 'Rubik', sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 6px 8px;
    margin-bottom: 4px;
    letter-spacing: 1px;
    border-radius: 0px;
    border: transparent;
    background-color: #878a86;
    color: #fff;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.ba-left-btn {
    position: absolute;
    top: -6px;
    float: left;
}

.ba-right-btn {
    position: absolute;
    top: -6px;
    right: 0px;
}


/*-----------------------------------
8. Footer 
-------------------------------------*/

.footer {
    padding-top: 60px;
    padding-bottom: 30px;
    font-weight: 400;
    margin-bottom:-15px;
}

.footer-title {
    margin-top: 15px;
    color: #fff;
}

.footer-widget {
    margin-bottom: 30px;
}

.footer-widget ul {
    margin-bottom: 25px;
}

.footer-widget ul li {
    line-height: 1;
    margin-bottom: 18px;
    font-size: 16px;
}

.footer-widget ul li a {
    font-size: 14px;
    color: #8e918e;
    text-transform: uppercase;
    letter-spacing: .75px;
    font-weight: 400;
}

.footer-widget ul li a:hover {
    color: #a5d897;
}

.footer-widget ul li i {
    margin-right: 15px;
    color: #a5d897;
}

.footer-widget .form-control {
    height: 50px;
    color: #7e7e7e;
    background-color: #333333;
    border: 0px solid;
    border-radius: 0px;
}

.footer-social {}

.footer-social img {
    width: 30px;
    margin-right: 10px;
}

.footer-social a span {
    font-size: 16px;
    padding-right: 10px;
    color: #58595a;
}

.footer-line {
    border-bottom: #222222 1px solid;
    margin-bottom: 20px;
    margin-top: 40px;
}

.footer-social a span:hover {
    color: #fff;
}

.footer-social a span.active {
    color: #fff;
}

.footer-link {
    color: #ffe451;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #ffe451;
    padding-bottom: 2px;
}

.footer-link:hover {
    color: #f7da3d;
    border-bottom: 1px solid #f7da3d;
}

.time-schedule {
    margin-bottom: 15px;
}

.time-schedule-text {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0px;
    font-weight: 700;
}


/*-------------- tiny footer ------------*/

.tiny-footer {
    background-color: #262926;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    color: #868b86;
    text-align: center;
    box-shadow: 0px 0px 8px #000000;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px
}


/*-----------------------------------
9. Pages 
-------------------------------------*/


/*-------------------------
 9.1 About us
---------------------------*/

.about-section {
    padding: 30px;
    padding-top: 0px;
}

.about-title {
    margin-bottom: 30px;
    color: #fff;
}

.about-img img {
    border-radius: 4px;
    position: relative;
}

.about-icon {
    position: absolute;
    bottom: 38%;
    left: 47%;
    text-align: center;
}

.about-list {}

.about-list ul {
    position: relative;
    color: rgb(50, 50, 50);
}

.about-list ul li {
    padding-left: 20px;
}

.about-list ul li:before {
    font-family: FontAwesome;
    display: block;
    position: absolute;
    left: 0;
    font-size: 14px;
    padding-top: 2px;
    content: "\f138";
}


/*-------------------------
 9.2  Service / single / sidebar
--------------------------*/

.service-block {
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 30px;
    border: 2px solid transparent;
    padding: 35px;
    text-align: center;
    z-index:8;
    position: relative;
    box-shadow: 0px 5px 30px 0px rgba(167, 167, 167, 0.16);
}

.service-block input, .service-block select {
    border-radius: 10px;
    margin-top:20px;
}

.service-icon {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 70px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.service-block h3 {
    font-size: 26px;
    font-weight: 700;
}

.service-icon:hover {}

.service-smalltext {
    margin-top: 10px;
}

.service-img img {
    margin-bottom: 30px;
}

.service-content {
    padding-bottom: 10px;
}

.service-btn-link {
    margin-bottom: 8px;
}

.unordered i {
    margin-right: 10px;
    color: #ff8119;
    font-size: 16px;
}

.service-block-nohover:hover {
    border: 2px solid transparent !important;
}


.service-block-v2 {
    margin: 15px;
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid transparent;
    padding: 35px;
    text-align: center;
    z-index:8;
    position: relative;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    transition: all .2s ease-in-out;
}

.service-block-v2 a:hover {
    color: black;
}

.service-block-v2:hover {
    transform: scale(1.05);
}

.service-block-full-image {
    margin: 15px;
    margin-bottom: 30px;
    background-color: #fff;
    text-align: center;
    z-index:8;
    position: relative;
    transition: all .2s ease-in-out;
}

.service-block-full-image img {
    border-radius: 20px;
}

.service-block-full-image:hover {
    transform: scale(1.05);
}


/*-----------------------------------------
  service side-nav
 ------------------------------------------*/

.sidenav {
    margin-bottom: 30px;
}

.sidenav ul li {
    position: relative;
    font-weight: 600;
    margin-bottom: 3px;
}

.sidenav ul li a {
    padding: 13px 0px 14px 20px;
    display: block;
    color: #626060;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    background-color: #f8faf5;
    border-radius: 2px;
}

.sidenav ul li a:hover {
    color: #fff;
    background-color: #f47e5e;
}

.sidenav ul li.active a {
    color: #fff;
    background-color: #f47e5e;
}

.sidenav ul li.active a:before {
    color: #fff;
    background-color: #f47e5e;
}

.service-sidenav-icon {
    font-size: 16px;
    color: #8e1e1a;
    padding-top: 5px;
    float: left;
    padding-right: 10px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

.sidenav a:focus,
a:active {
    background-color: transparent;
    border: 0px;
}


/*--------------------------
 9.5  Testimonial
---------------------------*/

.testimonial-carousel .owl-stage-outer {
    padding-top: 70px;
    padding-bottom: 15px;
}

.testimonial-wrapper {
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid transparent;
    padding: 35px;
    text-align: center;
    z-index:8;
    position: relative;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
    margin-left: 15px;
    margin-right: 15px;
}

.testimonial-block {}

.testimonial-icon {
    position: relative;
    top: -50px;
    text-align: right;
    height: 0px;
}

.testimonial-content {
    text-align: center;
    text-align: center;
}

.testimonial-pic {
    height: 30px;
}

.testimonial-text::before{
    font-family:Arial;
    content: "\201C";
    color:#78C0A8;
    font-size:4em;
}

.testimonial-text::after{
    content: '';
}

.testimonial-pic img {
    margin-bottom: 20px;
    width: 80px;
    position: relative;
    top: -75px;
    /*box-shadow: 0 0 6px rgba(0, 0, 0, 1);*/
}

.testimonial-text {
    font-size: 16px;
    font-style: italic;
    color: #7d7a79;
    line-height: 22px;
    font-weight: 400;
    max-height: 120px;
    overflow: scroll;
    padding-top: 15px;
}

.testimonial-user {
    padding-top: 14px;
    margin-bottom: 40px;
}

.testimonial-meta {
    padding-top:15px;
    line-height: 1.2;
    font-size: 20px;
    color: #5e6df4;
    letter-spacing: 1.2px;
    font-weight: 500;
    margin-top: 0px;
    display: block;
    border-top: 1px dashed rgba(0, 0, 0, 0.184);
}

.testimonial-wrapper span {
    line-height: 1.2;
    font-size: 14px;
    text-transform: uppercase;
    color: #000000;
    letter-spacing: 1.2px;
    font-weight: 700;
}

.testimonial-carousel .owl-dots {
    text-align: center !important;
    position: relative;
    bottom: 0px;
}

.testimonial-carousel .owl-nav {
    display: none;
}

.testimonial-carousel .owl-nav .owl-prev {
   
}

.testimonial-carousel.owl-nav .owl-next {
    }

.testimonial-carousel .owl-nav .owl-prev,
.owl-nav .owl-next:hover {
    
}

.testimonial-carousel.owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
}

.testimonial-carousel .owl-dots .owl-dot span {
    width: 11px;
    height: 11px;
    margin: 5px 4px;
    background: transparent;
    border-radius: 5px;
    border: 2px solid #6b6d6a;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
}

.testimonial-carousel .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background-color: #f47e5e;
    border: 2px solid #f47e5e;
}

.testimonial-carousel .owl-carousel .owl-item img {}


.rating-value {
  background-image: url("../../public/img/rating.svg");
  background-repeat: space no-repeat;
  width: 80px;
  height: 20px;
  margin: 10px auto 10px auto;
}

/*--------------------------
 9.6  team
 ----------------------------*/

.team-block {
    margin-bottom: 20px;
        text-align: center;
}

.team-section {}

.team-img {
    margin-bottom: 20px;
}

.team-content {
    text-align: center;
}

.team-title {
    margin-bottom:4px;
}

.team-meta {
    text-transform: uppercase;
    font-size: 13px;
    color: #84cc70;
    font-weight: 700;
    letter-spacing: .75px;
}


/*-------------------------
 9.8 Contact us
---------------------------*/

.contact-block {
    background-color: #ec1010;
    padding: 84px;
    color: #fff;
    text-align: center;
}

.contact-form {}

.contact-info {}

.contact-info-title {}

.contact-icon {}

.contact-info ul {}

.contact-info ul li {
    line-height: 40px;
}

.contact-info ul li i {
    margin-right: 20px;
    color: #a5d897;
}

.contact-title {}

.get-in-touch {}

.contact-social {}

.contact-social a span {}

.contact-social a span:hover {}


/*------------------------
10. Blog pages
--------------------------*/


/*----- post-----*/

.post-holder {
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 30px;
}

.post-img img {
    border-radius: 0px;
    margin-bottom: 30px;
}

.post-content {
    margin-top: 20px;
    margin-bottom: 30px;
}

.sticky-box,
.video-box {
    height: 50px;
    width: 50px;
    background-color: #8e1e1a;
    position: absolute;
    z-index: 8;
    top: 20px;
    right: 0px;
    left: 20px;
    border-radius: 50px;
    padding-top: 2px;
    line-height: 1;
}

.post-sticky,
.post-video {
    position: absolute;
    top: 14px;
    right: 19px;
    z-index: 9;
    color: #fff;
    font-size: 20px;
}

hr,
.post-hr {
    border-bottom: 1px solid #e6e7e9;
    margin-bottom: 30px;
    margin-top: 30px;
}


/*----- meta -----*/

.meta {
    margin-bottom: 20px;
    text-align: left;
    padding-right: 3px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
}

.meta-icon {}

.meta-date {
    color: #f47e5e;
}

.meta-author,
.meta-comments,
.meta-categories {
    color: #b9b9b9;
}

.meta-date,
.meta-author {
    margin-right: 15px;
}

.meta-tag {}


/*----- pagination -----*/

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
}

.st-pagination {
    text-align: center;
    margin-top: 30px;
}

.st-pagination .pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    line-height: 1.6;
    color: #fff;
    letter-spacing: 2px;
    text-decoration: none;
    font-size: 13px;
    border-radius: 2px;
    border: 1px solid #727471;
    background-color: #727471;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 8px;
    margin-bottom: 5px;
}

.st-pagination .pagination>li>a:focus,
.st-pagination .pagination>li>a:hover,
.st-pagination .pagination>li>span:focus,
.st-pagination .pagination>li>span:hover {
    z-index: 2;
    color: #fff;
    background-color: #84cc70;
    border: #84cc70 1px solid;
}

.st-pagination .pagination>li:first-child>a,
.st-pagination .pagination>li:first-child>span {
    margin-left: 0;
    border-radius: 2px;
}

.st-pagination .pagination>li:last-child>a,
.st-pagination .pagination>li:last-child>span {
    border-radius: 2px;
}

.st-pagination .pagination>.active>a,
.st-pagination .pagination>.active>a:focus,
.st-pagination .pagination>.active>a:hover,
.st-pagination .pagination>.active>span,
.st-pagination .pagination>.active>span:focus,
.st-pagination .pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #84cc70;
    border: 1px solid #84cc70;
}

.related-post-block {
    border-top: 1px solid #e6e7e9;
    margin-bottom: 30px;
}

.related-post-title {
    margin-top: 30px;
}

.related-post {}

.related-img img {
    margin-bottom: 30px;
}

.related-post-content {}

.post-meta {
    margin-right: 20px;
    line-height: 0px;
}


/*----- prev-next-----*/

.post-navigation {
    border-top: 1px solid #e6e7e9;
    border-bottom: 1px solid #e6e7e9;
    padding: 20px 0px 20px 0px;
}

.nav-links {
    text-decoration: none;
}

.nav-previous {}

.prev-link,
.next-link {
    color: #6b6d6a;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 20px;
    letter-spacing: 1px;
}

.prev-link i {
    margin-right: 5px;
}

.next-link i {
    margin-left: 5px;
}

.nav-next {}

.next-link {}

.previous-next-title {
    font-size: 18px;
    line-height: 22px;
    margin-top: 20px;
    color: #333333;
    font-weight: 700;
}


/*----- Author-post -----*/

.author-block {
    margin-top: 40px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e6e7e9;
}

.author-post {}

.author-img {}

.author-title {
    font-size: 22px;
    line-height: 22px;
    color: #fff;
}

.author-post-content {
    margin-bottom: 40px;
}

.author-text {
    margin-top: 15px;
}

.author-meta {
    padding-left: 0px;
    color: #fff;
}


/*----- Comments -----*/

.comment-area {
    margin-top: 40px;
    margin-bottom: 30px;
    border-bottom: 1px solid #ebecee;
}

.comment-title {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 30px;
}

.comment-list {
    margin-top: 40px;
}

.comment {}

.comment-body {}

.comment-header {
    margin-right: 10px;
}

.comment-author {}

.comment-author img {
    float: left;
}

.comment-info {
    padding-left: 100px;
}

.user-title {
    font-size: 18px;
    float: left;
    margin-bottom: 0px;
    margin-right: 30px;
}

.comment-meta-date {
    padding-bottom: 10px;
    color: #9a9fa4;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .75px;
    text-transform: uppercase;
}

.comment-content {
    margin-bottom: 10px;
    font-size: 16px;
}

.reply-link {}

.childern {
    padding: 30px;
    background-color: transparent;
    border-bottom: 1px solid #e6e7e9;
    border-top: 1px solid #e6e7e9;
}


/*----- leave-Comments -----*/

.leave-comments {
    margin-bottom: 30px;
}


/*------------------------
 12. Counter block 
--------------------------*/

.counter-block {}

.counter-section {}

.counter-content {}

.counter-title {}

.counter-text {}


/*------------------------
 12. Sidebar / Widgets
--------------------------*/


/*----- widget -----*/

.widget {
    margin-bottom: 30px;
    padding: 30px;
    border-radius: 2px;
    background-color: #f8faf5;
}

.widget-title {
    color: #302e2e;
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 500;
    text-transform: uppercase;
}

.widget ul {}

.widget li {}

.widget li a {}

.widget li a:hover {
    color: #f47e5e;
}

.widget-categories,
.widget-archives {}

.widget-categories ul li,
.widget-archives ul li {
    position: relative;
    font-weight: 500;
    margin-bottom: 8px;
}

.widget-categories ul li a,
.widget-archives ul li a {
    padding: 0px 0px 0px 18px;
    font-size: 15px;
    font-weight: 500;
    color: #626060;
}

.widget-archives {}

.recent-post {}

.recent-img img {
    margin-bottom: 20px;
}

.recent-title {}

.widget-tags {}

.widget-tags a {
    color: #fff;
    padding: 4px 8px;
    background-color: #727471;
    display: inline-block;
    margin-bottom: 5px;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 20px;
    margin-right: 5px;
    border: 0px;
}

.widget-tags a:hover {
    background-color: #f47e5e;
    color: #fff;
    border: 0px;
}

.widget-search button {
    background-color: #72bb5d;
    border: 1px solid #72bb5d;
    color: #fff;
    font-size: 12px;
    position: absolute;
    right: 0px;
    padding: 8px 15px;
    position: absolute;
    bottom: 0;
}

.widget-search .form-control {
    height: 49px;
}

.search-form {
    position: relative;
}


/*-------------styleguide-------------*/

.box-info {
    font-size: 16px;
    line-height: 1.6;
}

.circle-default-color {
    background-color: #84cc70;
    height: 162px;
    width: 162px;
    border-radius: 50%;
    border: 5px solid #ddf8d6;
}

.circle-primary-color {
    background-color: #f47e5e;
    height: 162px;
    width: 162px;
    border-radius: 50%;
    border: 5px solid #f9cabd;
}

.circle-heading-color {
    background-color: #302e2e;
    height: 162px;
    width: 162px;
    border-radius: 50%;
    border: 5px solid #797777;
}

.circle-secondary-color {
    background-color: #7d7a79;
    height: 162px;
    width: 162px;
    border-radius: 50%;
    border: 5px solid #c7c5c4;
}

.box-bg-color {}

.unordered ul {
    list-style: none;
    padding-left: 16px;
}

.unordered ul li {
    line-height: 2;
}

.ordered ol {
    list-style: decimal;
    margin-left: 20px;
}

.ordered ol li {
    line-height: 2;
}

.altenate {}

.altenate ul {
    list-style: none;
}

.altenate ul li {
    border-bottom: 1px solid #e1e0dd;
    line-height: 2.5;
}

.box-table {
    padding: 16px 30px;
    margin: 0 -15px 15px;
    border: 1px solid #dbdad8;
}


/*-------------404 error-------------*/

.error {
    padding-left: 300px;
}

.error-icon {
    float: left;
    font-size: 150px;
    color: #e56f4f;
}

.error h1 {
    font-size: 150px;
    font-weight: 700;
}


/*-------------map-------------*/

#map {}

#contact-map {
    width: 100%;
    height: 400px;
}

/*-------------Treatment--------*/

.treatment-details{
    margin: 10px;
}
.treatment-container{
    padding-bottom: 100px;
}

.treatment-row{
    margin-top: 8%;
}

#treatments {
    padding-top:190px;
    margin-top: -190px;
}

/*------------ Image-------*/

.img-rounded {
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
}

.img-zoom {
    transition: 0.4s;
    border-radius: 10px;
}

.img-zoom:hover {
    transform: scale(1.2);
}

.polaroid {
    width: 100%;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
    text-align: center;
    border-radius:20px;
    overflow: hidden;
  }

/*-------------FAQ-------------*/





/*==========  Non-Mobile First Method  ==========*/


/* Large Devices, Wide Screens */

@media only screen and (max-width:1200px) {
    .slider-captions {
        position: absolute;
        bottom: 50px;
    }
    .slider .owl-dots {
        min-width: 28%;
        position: absolute;
        bottom: 20px;
        text-align: center !important;
    }
}


/* Medium Devices, Desktops */

@media only screen and (max-width:992px) {}


/* Small Devices, Tablets */

@media only screen and (max-width:768px) {
    .slider-captions {
        position: absolute;
        bottom: 50px;
        text-align: center;
    }
    .slider-title {
        font-size: 30px;
        margin-bottom: 0px;
        font-weight: 500;
    }
    .slider .owl-nav .owl-prev i {
        display: block;
    }
    .slider .owl-nav .owl-next i {
        display: block;
    }
    .slider .owl-nav .owl-prev {
        font-size: 12px;
        padding: 3px 0px;
        height: 20px;
        width: 20px;
    }
    .slider .owl-nav .owl-next {
        font-size: 12px;
        padding: 3px 0px;
        height: 20px;
        width: 20px;
    }
    .slider .owl-dots {
        min-width: 100%;
        position: absolute;
        bottom: 4px;
        text-align: center !important;
    }
    .header {
        background-color: #333333;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .page-section {
        padding-top: 90px;
        padding-bottom: 50px;
    }
    .page-title {
        font-size: 30px;
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
        position: relative;
    }
    .header {
        background-color: #333333;
    }
    .room-block {
        padding: 10px;
    }
    button.btn {
        font-size: 9px;
    }
    .testimonial-content {}
}


/* Extra Small Devices, Phones */

@media only screen and (max-width:480px) {
    .slider-captions {
        bottom: 40px;
        text-align: center;
    }
    .slider-title {
        font-size: 18px;
    }
    .slider .owl-nav .owl-prev {
        font-size: 12px;
        padding: 3px 0px;
        height: 20px;
        width: 20px;
    }
    .slider .owl-nav .owl-prev i {
        display: block;
    }
    .slider .owl-nav .owl-next i {
        display: block;
    }
    .slider .owl-nav .owl-next {
        font-size: 12px;
        padding: 3px 0px;
        height: 20px;
        width: 20px;
    }
    .slider .owl-dots {
        min-width: 100%;
        position: absolute;
        bottom: -30px;
        text-align: center !important;
    }
    .header {
        background-color: #333333;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .page-section {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .page-title {
        font-size: 30px;
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
        position: relative;
    }
    .header {
        background-color: #333333;
    }
    .room-block {
        padding: 15px;
    }
    button.btn {
        font-size: 9px;
    }
    .testimonial-content {
        text-align: center;
        background-color: transparent;
        text-align: center;
        padding: 0px;
        position: inherit;
        bottom: 0px;
    }
}


/* Custom, iPhone Retina */

@media only screen and (max-width:320px) {
    .slider-captions {
        bottom: 40px;
        text-align: center;
    }
    .slider-title {
        font-size: 18px;
    }
    .slider .owl-nav .owl-prev i {
        display: block;
    }
    .slider .owl-nav .owl-next i {
        display: block;
    }
    .slider .owl-nav .owl-prev {
        font-size: 12px;
        padding: 3px 0px;
        height: 20px;
        width: 20px;
    }
    .slider .owl-nav .owl-next {
        font-size: 12px;
        padding: 3px 0px;
        height: 20px;
        width: 20px;
    }
    .slider .owl-dots {
        min-width: 100%;
        position: absolute;
        bottom: 4px;
        text-align: center !important;
    }
    .header {
        background-color: #333333;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .page-section {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .page-title {
        font-size: 30px;
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
        position: relative;
    }
    .room-block {
        padding: 15px;
    }
    button.btn {
        font-size: 9px;
    }
    .testimonial-content {
        text-align: center;
        background-color: transparent;
        text-align: center;
        padding: 0px;
        position: inherit;
        bottom: 0px;
    }
}

/************
* Bootstrap Mods
************/

.btn {
    margin-right: 5px;
    border-radius: 15px;
}

.treatment.icon {
    width:100px;
    border-radius: 20px;
}

.ds_form_labels {
    text-align: left;
    width: 100%;
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: bold;
    text-transform: none;
}

/* ABHA Card */

.abha-card {
    width: 100%;
    text-align: left;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 16px;
    background: linear-gradient(135deg, #2b6cb0, #3182ce);
    color: white;
    font-family: Arial, sans-serif;
    display: flex;
    align-items: center;
    line-height: 18px;
    max-width: 330px;
  }

  .abha-card img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 12px;
  }

  .abha-card .abha-card-details {
    display: flex;
    flex-direction: column;
  }

  .abha-card .abha-card-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .abha-card .abha-card-content {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .abha-card .abha-card-id {
    font-size: 12px;
    font-weight: bold;
    background: rgba(255, 255, 255, 0.2);
    padding: 8px;
    border-radius: 8px;
    display: inline-block;
    margin-top: 8px;
  }

  .abha-card .abha-card-footer {
    font-size: 12px;
    opacity: 0.8;
    margin-top: 8px;
  }